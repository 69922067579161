<template>
  <div class="pb-10">
    <section class="pb-2 flex-1 rounded-t-2xl rounded-b-lg border-[1px] border-gray-200 shadow-xl h-full overflow-hidden">

      <div v-if="imgUrl" class="relative flex flex-1 flex-col overflow-hidden">
        <div :class="[imgZoomClassname]" :style="`filter:contrast(${imgContrast});`" class="w-full relative aspect-square max-h-40 object-contain">
          <CPicture lazy
                    :src="imgUrl"
                    :fp-x="fpX" :fp-y="fpY"
                    :fit="fit" sizes="(max-width: 640px) 420px,600px"
                    :img-contrast="imgContrast" :vibrance="vibrance"
                    :quality="80"
                    class="[transition:transform_6s_ease-out] hover:[transform:scale(1.28)] hover:[transition:transform_0.6s_ease-in]" />
        </div>
        <div class="ml-2 sm:ml-3 sm:mr-3 absolute top-3 left-0 font-bold text-black text-sm flex flex-wrap flex-grow-0 gap-y-2 items-center">
          <div v-for="tag in tagCloud" class="mr-2 px-2 bg-white bg-opacity-80 rounded-lg">
            <div>{{ tag }}</div>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap flex-1 justify-between items-stretch">
        <div class="flex-1">

          <div>
            <div class="pr-3 flex justify-end items-center">
              <div v-if="priceInfo" class="-translate-y-4 -mb-4 px-2 tracking-wider rounded-2xl max-w-fit bg-white"><span class="text-xs">p.P. ab</span> <span class="text-accent-500 text-xl sm:text-xl">{{ priceInfo }} &euro;</span></div>
              <div v-else class="mb-4"></div>
            </div>
            <div class="mb-3.5 px-6 flex flex-col justify-center">
              <div class="text-xs text-lux-400 uppercase">{{ claim }}</div>
              <CHeading :heading="heading" class="text-xl"><strong>{{ title }}</strong></CHeading>
              <div class="mt-1 text-sm tracking-wider">{{ description }}</div>
            </div>
          </div>

          <div class="flex flex-col contrast">
            <div v-for="item in items">
              <NuxtLink :href="item.href" :external="isExternalLink(item)" :target="getTarget(item)">
                <div class="py-3 px-6 border-t-[1px] flex flex-col flex-wrap justify-center border-stone-200  h-full">
                  <CHeading :heading="`h${Number.parseInt(heading[1]) + 1}`" class="text-glam-600"><strong><span class="font-normal">{{ item.caption }}</span></strong></CHeading>
                  <CHeading v-if="item.description" :heading="`h${Number.parseInt(heading[1]) + 2}`" class="text-sm text-neutral-500">{{ item.description }}</CHeading>
                </div>
              </NuxtLink>
            </div>
          </div>

        </div>
      </div>

    </section>
  </div>
</template>

<script setup lang="ts">
export interface ListItemType {
  caption: string
  description?: string
  href: string
  target?: '_blank' | '_parent' | '_self' | '_top' | (string & {}) | null;
}

export interface Props {
  claim?: string
  title?: string
  description?: string
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'clamp' | 'clip' | 'crop' | 'facearea' | 'fillMax' | 'max' | 'min' | 'scale'
  fpX?: number
  fpY?: number
  heading?: string
  imgContrast?: number
  vibrance?: number
  imgDisclaimer?: string
  imgUrl?: string
  flight?: boolean
  noContrast?: boolean
  priceInfo?: string
  items: ListItemType[]
  tags?: string[]
  zoomDirection?: 'in' | 'out' | 'none'
}

const props = withDefaults(defineProps<Props>(), {
  fpX: 0.5,
  fpY: 0.5,
  heading: 'h4',
  imgContrast: 1.0,
  vibrance: 80,
  zoomDirection: 'out',
})

const isExternalLink = (item: ListItemType): boolean => item.href.startsWith('https://')

function getTarget(item: ListItemType): '_blank' | '_parent' | '_self' | '_top' | (string & {}) | null {

  if (item.href.startsWith('https://') && !item.href.startsWith('https://kreuzfahrten') || item.target === '_blank') {
    return '_blank'
  }

  return '_self'
}

const imgZoomClassname = {
  'in': '[animation:cos-animation-zoom-out_40s_linear_1_0s]',
  'out': '[animation:cos-animation-zoom-in_40s_linear_1_0s]',
  'none': '',
}[props.zoomDirection]

let tagCloud: string[] = []
if (props.tags !== undefined) {
  tagCloud = tagCloud.concat(props.tags)
}
if (props.flight) {
  tagCloud = tagCloud.concat('inkl. Flug')
}
</script>
